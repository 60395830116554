import React from 'react';
import packageJson from '../../../package.json';
import styled from 'styled-components';
import { colors } from '../../commons/colors';

const FooterStyle = styled.footer`
  width: 100%;
  background-color: ${colors.white};
  color: black;
  text-align: center;
  padding: 10px 0 20px;
  border-top: 1px solid #cfcfcf;
  p {
    margin: 0;
    strong {
      color: ${colors.mediumGreen};
  }
  .divider {
    
    margin: 10px 0;
  }
`;
export const FooterComponent = () => {
  return (
    <FooterStyle>
      <div className='divider' />
      <p>
        &copy; 2024 - Colegio Villa Heroica de Tenango del Valle - All rights
        reserved{' '}
      </p>
      <p>
        Designed by <strong>CVH TI Department</strong> v{packageJson.version}{' '}
        (Draft)
      </p>
    </FooterStyle>
  );
};
