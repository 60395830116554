import React from 'react';
import { HomeTemplate } from '../../templates/HomeTemplate/homeTemplate';

export const HomeView = () => {
  return (
    <div>
      <HomeTemplate />
    </div>
  );
};
