import styled from 'styled-components';
import { colors } from '../../commons/colors';
import { Button } from 'primereact/button';

export const HeroContainer = styled.div`
  position: relative;
  height: 85vh;
  background-size: cover;
  background-position: center;
  color: white;
  min-height: 500px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroContent = styled.div`
  text-align: center;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Description = styled.p`
  font-size: 1.5rem;
  margin: 0;
  font-style: italic;
`;

export const SmallText = styled.p`
  font-size: 1rem;
  margin: 0;
  margin-top: 30px;
  display: block;
  font-weight: bold;
  span {
    padding: 5px;
    border: 4px solid ${colors.lightPurple};
  }
`;

export const ActionButton = styled(Button)`
  padding: 10px 20px;
  border: none;
  font-size: 1.2rem;
  margin-top: 40px;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
export const LocationText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 20px;
`;
export const MouseDown = styled.div`
  width: 25px;
  height: 45px;
  border: 2px solid #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -12.5px;
  border-radius: 12px;

  &:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #fff;
    border-radius: 100%;
    left: 50%;
    top: 10px;
    margin-left: -2.5px;
    transition: all 0.3s ease-in;
    animation: rotateplane 2s infinite;
  }

  @keyframes rotateplane {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 10px);
    }
  }

  @-webkit-keyframes rotateplane {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const WhoWeAreContainer = styled.div`
  background-color: ${colors.lightPurple};
  padding: 50px 0;
  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  .description {
    font-size: 1.2rem;
    text-align: center;
    color: white;
    margin-top: 20px;
  }
`;

export const FeaturesContainer = styled.div`
  padding: 50px 0;
  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.textColorDarkMedium};
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.textColorDarkMedium};
    margin-bottom: 0;
  }
  .description {
    font-size: 1rem;
    text-align: center;
    color: ${colors.textColorDarkMedium};
  }
  .row {
    margin-top: 40px;
  }
  i {
    font-size: 2rem;
    color: ${colors.mediumGreen};
  }
`;

export const ContactUsContainerForm = styled.div`
  background-color: ${colors.lightBlue};
  padding: 50px 0;
  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.textColorDarkMedium};
  }
  .description {
    font-size: 1.2rem;
    text-align: center;
    color: white;
    margin-top: 20px;
  }
  input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
  }
`;

export const LogosContainer = styled.div`
  padding: 50px 0;
  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.textColorDarkMedium};
    margin-bottom: 30px;
  }
  img {
    margin: 20px;
  }
`;
