export const colors = {
  lightGreen: '#C3FF93',
  mediumGreen: '#51d651',
  lightYellow: '#FFDB5C',
  lightPink: '#FF70AB',
  lightPurple: '#A0A0FF',
  lightBlue: '#70ABFF',
  mediumBlue: '#2365df',
  lightGray: '#E0E0E0',
  lightOrange: '#FFA270',
  textColorDarkMedium: '#232323',
  white: '#FFFFFF',
};
