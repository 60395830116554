import React from 'react';
import {
  HeaderButtonRedirectToHome,
  HeaderDesktopTitleText,
  HeaderOptionNavigation,
} from './headerStyles';

const logoCVH = require('../../img/escudoCVH.png');

export const HeaderComponent = () => {
  return (
    <header>
      <div
        className='row d-sm-none mobileView d-flex align-items-center'
        style={{ position: 'sticky' }}>
        <div className='col text-start'>
          <HeaderButtonRedirectToHome>
            <img src={logoCVH} alt='Logo CVH' width={30} />
            <HeaderDesktopTitleText className='ms-2 fw-bold'>
              CVH
            </HeaderDesktopTitleText>
          </HeaderButtonRedirectToHome>
        </div>
        <div className='col text-end'>
          <HeaderOptionNavigation disabled>
            <i className='pi pi-user me-2' />
            Log In
          </HeaderOptionNavigation>
        </div>
      </div>
      <div
        className='row d-sm-flex desktopView align-items-center d-none py-2'
        style={{ position: 'sticky' }}>
        <div className='col-5 d-flex align-items-center text-start'>
          <HeaderButtonRedirectToHome>
            <img src={logoCVH} alt='Logo CVH' width={40} />
            <HeaderDesktopTitleText className='ms-2 fw-bold'>
              Colegio Villa Heroica
            </HeaderDesktopTitleText>
          </HeaderButtonRedirectToHome>
        </div>
        <div className='col-7 text-end'>
          <HeaderOptionNavigation disabled>
            <i className='pi pi-user me-2' />
            Log In
          </HeaderOptionNavigation>
        </div>
      </div>
    </header>
  );
};
