import styled from 'styled-components';
import { colors } from '../../commons/colors';

export const HeaderDesktopTitleText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.mediumGreen};
  margin: 0;
`;

export const HeaderButtonRedirectToHome = styled.button`
  border: none;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  p {
    transition: color 0.3s;
  }
  &:hover {
    p {
      color: ${colors.textColorDarkMedium};
    }
  }
`;

export const HeaderOptionNavigation = styled.button`
  color: ${colors.textColorDarkMedium};
  font-weight: bold;
  background-color: transparent;
  transition: color 0.3s;
  border: none;
  &:hover {
    color: ${colors.mediumGreen};
  }
`;
