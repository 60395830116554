import React, { useState } from 'react';
import {
  HeroContainer,
  Overlay,
  HeroContent,
  Title,
  Description,
  SmallText,
  MouseDown,
  WhoWeAreContainer,
  FeaturesContainer,
  LocationText,
  ContactUsContainerForm,
  LogosContainer,
} from './homeTemplateStyles';
import { featuresArray } from './featuresArray';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
const heroImage = require('../../img/home.jpg');
const logoCVH = require('../../img/escudoCVH.png');
const logoKinder = require('../../img/logoKinder.png');
export const HomeTemplate = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const numerosPorNivel: any = {
    Preescolar: '7225730740',
    Primaria: '7225739991',
    Secundaria: '7225730740',
  };
  const options = ['Preescolar', 'Primaria', 'Secundaria'];
  const [level, setLevel] = useState(options[0]);

  const sendWhatsappMessage = () => {
    const url = `https://api.whatsapp.com/send?phone=${numerosPorNivel[level]}&text=Hola,%20me%20gustaría%20más%20información%20sobre%20el%20nivel%20${level}%20para%20mi%20hijo(a).%20Soy%20${name}.%20Este%20es%20mi%20mensaje:%20${message}`;
    window.open(url, '_blank');
  };
  return (
    <div>
      <HeroContainer style={{ backgroundImage: `url(${heroImage})` }}>
        <Overlay>
          <HeroContent>
            <Title>Colegio Villa Heroica</Title>
            <Description>
              "Educación de calidad y calidez con espíritu humano."
            </Description>
            <SmallText>
              <span>Preescolar - Primaria - Secundaria</span>
            </SmallText>
            <LocationText>
              Octavio Paz #121, Tenango del Valle, Estado de México
            </LocationText>
            {/* <ActionButton>Ingresar</ActionButton> */}
            <MouseDown />
          </HeroContent>
        </Overlay>
      </HeroContainer>
      <WhoWeAreContainer className='row justify-content-center'>
        <p className='title col-12'>¿Quiénes somos?</p>
        <p className='description col-11 col-md-10 text-center'>
          El Colegio Villa Heroica se encuentra ubicado en Tenango del Valle,
          Estado de México. Fundado en 2005 con el nivel de primaria, ha crecido
          constantemente para convertirse en una institución destacada en la
          región. En 2007, se expandió para incluir secundaria y en 2020, con
          gran entusiasmo, se inauguró la sección de preescolar, brindando una
          educación integral desde los primeros años de los niños y niñas.
        </p>
      </WhoWeAreContainer>
      <FeaturesContainer className='row justify-content-center'>
        <p className='title col-12'>¿Que nos destaca?</p>
        {featuresArray.map((feature, index) => (
          <div key={index + feature.title} className='col-10 col-sm-6 col-md-4'>
            <i className={`${feature.icon}`} />
            <p className='subtitle'>{feature.title}</p>
            <p className='description'>{feature.description}</p>
          </div>
        ))}
      </FeaturesContainer>
      <LogosContainer className='row justify-content-center'>
        <p className='title col-12'>Se parte de nosotros</p>
        <div className='col-10 col-md-6'>
          <img
            src={logoCVH}
            alt='Colegio Villa Heroica'
            className='logo'
            width={150}
          />
          <img
            src={logoKinder}
            alt='Kinder Villa Heroica'
            className='logo'
            width={150}
          />
        </div>
      </LogosContainer>
      <ContactUsContainerForm className='row justify-content-center'>
        <p className='title col-12'>Contáctanos</p>
        <form className='col-11 col-md-6'>
          <div className='mb-4'>
            <FloatLabel>
              <InputText
                id='username'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor='username'>Nombre</label>
            </FloatLabel>
          </div>
          <div className='mb-4'>
            <FloatLabel>
              <InputTextarea
                id='message'
                value={message}
                rows={5}
                cols={30}
                onChange={(e) => setMessage(e.target.value)}
                className='w-100'
              />
              <label htmlFor='message'>Mensaje</label>
            </FloatLabel>
          </div>
          <div className='mb-5 form-check'>
            <label className='form-check-label mb-3' htmlFor='exampleCheck1'>
              Selecciona el nivel educativo de tu interés y nos pondremos en
              contacto contigo:
            </label>
            <SelectButton
              value={level}
              onChange={(e) => setLevel(e.value)}
              options={options}
              className='w-100'
            />
          </div>
          <Button
            type='submit'
            label='Enviar'
            size='large'
            disabled={name === '' || message === ''}
            onClick={sendWhatsappMessage}
          />
        </form>
      </ContactUsContainerForm>
    </div>
  );
};
