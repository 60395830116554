import React from 'react';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/saga-orange/theme.css';
import 'primeicons/primeicons.css';
import './App.css';
import { MainLayout } from './Layout/main/main';

export const App = () => {
  return (
    <div className='App'>
      <PrimeReactProvider>
        <MainLayout />
      </PrimeReactProvider>
    </div>
  );
};

export default App;
