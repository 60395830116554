import React from 'react';
import styled from 'styled-components';
import { HeaderComponent } from '../../components/header/header';
import { MainRoutes } from '../../components/routes/routes';
import { ScrollTop } from 'primereact/scrolltop';
import { FooterComponent } from '../footer/footer';

const StyledMainContainer = styled.div`
  padding: 0;
  margin: 0;
  width: '100% !important';
  overflow: 'auto';
  overflow-x: hidden;
`;

export const MainLayout = () => {
  return (
    <StyledMainContainer>
      <HeaderComponent />
      <MainRoutes />
      <FooterComponent />
      <ScrollTop threshold={60} />
    </StyledMainContainer>
  );
};
