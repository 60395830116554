export const featuresArray: {
  title: string;
  description: string;
  icon: string;
}[] = [
  {
    title: 'Desarrollo de Habilidades Socioemocionales',
    description:
      'Programas que enseñen habilidades de resolución de conflictos, inteligencia emocional y liderazgo para preparar a los estudiantes para la vida adulta.',
    icon: 'bx bxs-smile',
  },
  {
    title: 'Deportes y Actividad Física',
    description:
      'Actividades deportivas y físicas que fomenten la salud y el bienestar de los estudiantes.',
    icon: 'bx bx-football',
  },
  {
    title: 'Arte y Creatividad',
    description:
      'Programas que fomenten la creatividad y la expresión artística de los estudiantes.',
    icon: 'bx bxs-paint',
  },
  {
    title: 'Educación Ambiental',
    description:
      'Iniciativas y programas educativos que fomenten la conciencia ambiental y prácticas sostenibles dentro y fuera del campus.',
    icon: 'bx bxs-tree',
  },
  {
    title: 'Valores',
    description:
      'Enseñanza de valores éticos y morales para fomentar el respeto, la responsabilidad y la empatía en los estudiantes.',
    icon: 'bx bxs-donate-heart',
  },
  {
    title: 'Seguridad',
    description:
      'Medidas y protocolos de seguridad para garantizar un entorno seguro y protegido para los estudiantes y el personal.',
    icon: 'bx bxs-shield-alt-2',
  },
];
