import { Route, Routes } from 'react-router-dom';
import { HomeView } from '../../views/Home/homeView';

export const MainRoutes = () => {
  return (
    <Routes>
      {/*       <Route path='/' element={<Navigate to='/home' />} /> */}
      <Route path='/' element={<HomeView />} />
    </Routes>
  );
};
